<template>
    <v-card class="rounded-xl pa-3" :color="data.verein ? data.verein.farben.blocks || '#dcdcdc' : '#dcdcdc' ">
        <v-row justify="center">
        <v-col cols="auto" align-self="center">
            <v-badge avatar left overlap tile color="transparent">
            <template v-slot:badge v-if="data.verein">
                <v-avatar tile>
                <v-img contain :src="data.verein ? data.verein.logo : ''"></v-img>
                </v-avatar>
            </template>
            <v-avatar size="80">
                <v-img contain :src="data.person.id && data.person.img ? data.person.img : '/img/user_icon.png'" />
            </v-avatar>
            </v-badge>
        </v-col>  
        <v-col cols="7" md="auto" align-self="center">
            <h3>{{data.person.vorname}} {{data.person.nachname}}</h3>
            <b>{{data.person.funktion}}</b>
        </v-col>
        <v-col cols="12">
            <v-row justify="center" no-gutters>
            <v-btn v-if="(data.person.telefonpublic || (data.person.public_data && data.person.public_data.telefon)) && data.person.telefon" class="mx-2" rounded :color="data.verein ? data.verein.farben.primary : 'secondary'" :style="data.verein ? 'color: '+data.verein.farben.secondary : ''">
                <font-awesome-icon icon="fa-duotone fa-phone" class="mr-2" size="lg" />{{data.person.telefon}}
            </v-btn>
            <v-btn v-if="!data.person.public_data || (data.person.public_data && data.person.public_data.email)" rounded :href="`mailto:${data.person.email}`" class="mx-2" target="_blank" :color="data.verein ? data.verein.farben.primary : 'secondary'" :style="data.verein ? 'color: '+data.verein.farben.secondary : ''">
                <font-awesome-icon icon="fa-duotone fa-at" class="mr-2" size="lg" />E-Mail
            </v-btn>
            </v-row>
        </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Ansprechpartner-Card',
    props: ['verein', 'person'],
    data(){
        return {
            data: {
                verein: '',
                person: ''
            },
        }
    },
    computed: {
        ...mapGetters({
            vereine: 'vereine'
        })
    },
    mounted(){
        this.data.verein = this.vereine.all.find(item => item.id == this.verein.id)
        if(this.person.id && this.verein.mitarbeiter?.length > 0){
            this.data.person = this.verein.mitarbeiter.find(item => item.id == this.person.id) || this.person
        }
    }
}
</script>