<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-if="offer.active"
    value="true"
    persistent
    :overlay-color="offer && offer.verein ? offer.verein.farben.primary : ''"
    overlay-opacity="0.85"
    :style="$vuetify.breakpoint.smAndDown ? 'background-color: #fff;' : ''"
    :content-class="$vuetify.breakpoint.smAndDown ? undefined : 'rounded-xl'"
    :max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : '100vw'"
  >
    <v-card flat :class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : 'rounded-0'" style="overflow: hidden;">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-expand-transition>
          <v-carousel
            continuous
            cycle
            :show-arrows="hover && show_gallery && offer.fotos.length > 1"
            hide-delimiters
            :height="show_gallery ? 400 : 200"
            style="background-color: #fff; padding-top: env(safe-area-inset-top);"
            :style="$vuetify.breakpoint.smAndDown ? show_gallery ? 'position: fixed; top: 0; z-index: 5000; height: calc(400px + env(safe-area-inset-top));' : 'position: fixed; top: 0; z-index: 5000; height: calc(200px + env(safe-area-inset-top));' : show_gallery ? 'width: 50vw; height: calc(400px + env(safe-area-inset-top));' :  'width: 50vw; height: calc(200px + env(safe-area-inset-top));'"
          >
            <v-carousel-item
              v-for="(foto, i) in offer.fotos && offer.fotos.length > 0 ? offer.fotos : [offer.fallback_image]"
              :key="i"
            >
              <v-img :height="show_gallery ? 400 : 200" :src="foto.img"></v-img>
            </v-carousel-item>
            <v-fade-transition>
              <v-overlay
                v-if="!show_gallery"
                absolute
                :color="offer.verein ? offer.verein.farben.primary : '#121212'"
                class="text-center"
              >
                <v-avatar tile size="80">
                  <v-img
                    contain
                    :src="offer.verein ? offer.verein.logo : ''"
                  ></v-img>
                </v-avatar>
                <br />
                <v-chip
                  class="mt-2 font-weight-bold"
                  :color="offer.verein ? offer.verein.farben.primary : ''"
                  :style="`color: ${
                    offer.verein ? offer.verein.farben.secondary : '#000;'
                  };`"
                >
                  {{ offer.verein ? offer.verein.name : '' }}
                </v-chip>
              </v-overlay>
            </v-fade-transition>
            <v-row class="py-4" justify="space-between" style="position: absolute; top:0; left: 10px; width: 100%; z-index: 9999;">
              <v-col cols="auto" class="pl-4">
                <v-btn
                  v-if="offer.fotos.length > 0"
                  :color="offer.verein ? offer.verein.farben.secondary : ''"
                  icon
                  small
                  @click="show_gallery = !show_gallery"
                >
                  <font-awesome-icon icon="fa-solid fa-images" size="2x" v-if="!show_gallery" />
                  <font-awesome-icon icon="fa-solid fa-image-slash" size="2x" v-if="show_gallery" />
                </v-btn>
              </v-col>
              <v-col cols="auto" class="text-right">
                <v-btn
                  :color="offer.verein ? offer.verein.farben.secondary : ''"
                  :style="`color: ${
                    offer.verein ? offer.verein.farben.primary : '#000;'
                  };`"
                  fab
                  small
                  @click="closing()"
                >
                  <font-awesome-icon icon="fa-light fa-xmark" size="2x" />
                </v-btn>
              </v-col>
            </v-row>
          </v-carousel>
          </v-expand-transition>
        </template>
      </v-hover>
      
      <v-row class="pt-2 pl-4" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: calc(200px + env(safe-area-inset-top)); max-width: 100vw; z-index: 1000;' : undefined">
        <v-col cols="12">
          <h2>{{ offer.name }}</h2>
          <h4 :style="offer.verein ? `color: ${offer.verein.farben.primary};` : ''" v-if="offer.sportart">
            <font-awesome-icon v-if="offer.sportart.iconset == 'Fontawesome'" :icon="offer.sportart.icon" size="xl" class="mr-1" />
            <v-icon :color="offer.verein ? offer.verein.farben.primary : ''" v-if="offer.sportart.iconset == 'Material' || !offer.sportart.iconset">{{ offer.sportart ? offer.sportart.icon : '' }}</v-icon>
            {{ offer.sportart.name }}
          </h4>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-card flat class="pa-0 ma-0">
          <v-card-subtitle v-if="offer.wiederholung == 'Einmaliger Termin'">
              <v-icon>mdi-calendar</v-icon>
              {{ parseDate(offer.termin) }}
              <v-icon class="ml-2">mdi-clock</v-icon>
              {{ offer.termin.beginn }} Uhr
          </v-card-subtitle>
          <v-card-subtitle v-if="offer.wiederholung == 'Mehrere Termine'">
              <small class="font-weight-bold">Nächster Termin</small><br>
              <v-icon class="mr-1">mdi-calendar</v-icon>
              {{ parseDate(find_next_termin(offer.termine)) }}
              <v-icon class="ml-2">mdi-clock</v-icon>
              {{ find_next_termin(offer.termine).beginn }} Uhr
          </v-card-subtitle>
          <v-card-subtitle v-if="offer.wiederholung == 'Regelmäßige Termine' && offer.zeitraum && offer.zeitraum_termine && offer.zeitraum_termine.length > 0 ">
              <small>Jeden {{format_termine(offer.zeitraum)}}<br><b>Nächster Termin:</b></small><br>
              <v-icon>mdi-calendar</v-icon>
              {{offer.zeitraum_termine[0].wochentag.substring(0,2)}}, {{ offer.zeitraum_termine[0].datum_format }}
              <v-icon class="ml-2">mdi-clock</v-icon>
              {{ offer.zeitraum_termine[0].uhrzeit.beginn }} Uhr
          </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" class="mx-0 px-0" :style="$vuetify.breakpoint.smAndDown ? 'padding-bottom: 150px;' : ''">
          <v-tabs
            v-model="tab"
            fixed-tabs
            centered
            :color="offer.verein ? offer.verein.farben.primary : 'secondary'"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />Infos
            </v-tab>

            <v-tab href="#tab-2">
              <font-awesome-icon icon="fa-solid fa-address-card" class="mr-2" />Kontakt
            </v-tab>

            <v-tab href="#tab-3">
              <font-awesome-icon icon="fa-solid fa-shield" class="mr-2" />Verein
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++  INFOS  +++++++++++++++++++++++++++++++++++
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <v-tab-item :key="0" value="tab-1">
              <v-row justify="center" class="pa-4">
                <v-col cols="12">
                  <p class="text-justify" v-html="offer.beschreibung"></p>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <small class="mr-1">Alter:</small>
                  <v-chip
                    :color="offer.verein ? offer.verein.farben.primary : ''"
                    :style="offer.verein && alter == user.data.alter ? `color: ${offer.verein.farben.secondary}`: ''"
                    class="mx-1 font-weight-bold"
                    :outlined="alter != user.data.alter"
                    small
                    v-for="alter in altersgruppen"
                    :key="alter"
                  >
                    {{ alter }}
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="offer.material && offer.material.length > 0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="offer.material && offer.material.length > 0">
                  <h4 class="mb-3" :style="offer.verein ? `color: ${offer.verein.farben.primary};` : ''">Benötigtes Equipment</h4>
                  <v-row>
                    <v-col
                      cols="4"
                      class="text-center"
                      v-for="equipment in offer.material"
                      :key="equipment.name"
                    >
                      <font-awesome-icon v-if="equipment.iconset == 'fa'" :icon="equipment.icon" size="xl" class="mr-1" />
                      <v-icon color="black" v-if="equipment.iconset == 'mdi' || !equipment.iconset">mdi-{{ equipment.icon }}</v-icon>
                      <br />
                      <small class="font-weight-light">{{ equipment.name }}</small>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="offer.material">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="offer.veranstaltungsort">
                  <v-row>
                    <v-col cols="auto" align-self="center">
                      <v-btn x-large icon><v-icon>mdi-google-maps</v-icon></v-btn>
                    </v-col>
                    <v-col align-self="center">
                      <b>{{ offer.veranstaltungsort.name }}</b>
                      <br />
                      {{ offer.veranstaltungsort.adresse }}
                      <br />
                      <span>
                        {{ offer.veranstaltungsort.plz }}
                        {{ offer.veranstaltungsort.ort }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++ KONTAKT +++++++++++++++++++++++++++++++++++
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <v-tab-item :key="1" value="tab-2">
              <v-row justify="center" class="pa-4 text-center">
                <v-col cols="12">
                  <h2 class="font-weight-light">Du hast Fragen?</h2>
                </v-col>
                <v-col cols="12" md="auto" v-for="(person, index) in offer.ansprechpartner" :key="'Person'+index">
                  <ansprechpartner :verein="offer.verein" :person="person" />
                </v-col>
              </v-row>
            </v-tab-item>

            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
            +++++++++++++++++++++++++++++++  VEREIN  ++++++++++++++++++++++++++++++++++
            +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <v-tab-item :key="2" value="tab-3">
              <v-row justify="center" class="pa-4" v-if="offer.verein">
                <v-col cols="3" md="2" align-self="center">
                  <v-img :src="offer.verein.logo" contain />
                </v-col>
                <v-col cols="auto" class="text-center" align-self="center">
                  <h2>{{offer.verein.name}}</h2>
                  <v-btn rounded class="mt-3" :color="offer.verein ? offer.verein.farben.primary : 'secondary'" :style="offer.verein ? 'color: '+offer.verein.farben.secondary : ''" v-if="offer.verein.website" :href="offer.verein.website" target="_blank">
                    <font-awesome-icon icon="fa-duotone fa-browser" class="mr-2" size="lg" />Website des Vereins
                  </v-btn>
                </v-col>
                <v-col cols="12" md="10" class="text-justify">
                  <p>{{offer.verein.beschreibung}}</p>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col> 
        <v-col cols="12" :style="$vuetify.breakpoint.smAndDown ? `position: fixed; bottom: 0; width: 100vw; left: 0; background: ${offer.verein ? offer.verein.farben.primary : '#121212'} ; background: linear-gradient(0deg,  ${offer.verein ? offer.verein.farben.primary.substring(0,7)+'C0' : '#121212C0'} 20%,  ${offer.verein ? offer.verein.farben.primary.substring(0,7)+'60' : '#12121260'} 70%, ${offer.verein ? offer.verein.farben.primary.substring(0,7)+'00' : '#12121200'} 95%);` : ''">
          <v-card :color="!user.data.activated ? 'error' : 'transparent'" flat>
          <v-row justify="center" class="py-5">
            <v-col cols="auto" class="text-center pr-0" align-self="center" v-if="user.data.activated && !user.anmeldungen.find(item => item.veranstaltung == offer.id) && offer.anmeldungen.length < offer.teilnehmerzahl">
              <v-btn
                x-large
                rounded
                elevation="20"
                :disabled="!user.data.activated"
                :color="offer.verein ? offer.verein.farben.primary : ''"
                :style="`color: ${
                  offer.verein ? offer.verein.farben.secondary : '#000;'
                };`"
                @click="user.data.kinder ? eltern_anmeldung = true : anmelden()"
              >
                Jetzt anmelden
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center" v-if="user.anmeldungen.find(item => item.veranstaltung == offer.id)">
              <v-btn
                x-large
                rounded
                :color="offer.verein ? offer.verein.farben.primary : ''"
                :style="`color: ${
                  offer.verein ? offer.verein.farben.secondary : '#000;'
                };`"
                @click="cancel()"
              >
                <font-awesome-icon class="mr-4" icon="fa-duotone fa-ban" size="2x" />Anmeldung stornieren
              </v-btn>
            </v-col>
            <v-col cols="auto" align-self="center" v-if="false">
              <v-btn fab>
                <font-awesome-icon icon="fa-light fa-star" size="xl" />
              </v-btn>
            </v-col>
            <v-col cols="11" class="pt-5 text-center" v-if="!user.data.activated">
              <small>Dein Account wurde von deinem Erziehungsberechtigten noch nicht freigeschaltet. Daher kannst du dich noch nicht für eine Veranstaltung anmelden.</small>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog content-class="rounded-xl" max-width="700" v-model="eltern_anmeldung" v-if="$vuetify.breakpoint.mdAndUp">
      <v-card class="rounded-xl">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
              Wen möchtest du anmelden?
          </v-toolbar-title>
          <v-btn icon @click="eltern_anmeldung = false"><font-awesome-icon icon="fa-light fa-xmark" /></v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>  
        <v-row justify="center" class="pa-5">
          <v-col cols="12">

          </v-col>
          <v-col cols="8" v-for="(kind, index) in kinder" :key="'kind_anmeldung_'+index">
            <v-btn rounded block large :color="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname) ? 'success' : offer.verein ? offer.verein.farben.primary : 'secondary'" :style="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname) ? '' : `color: ${offer.verein ? offer.verein.farben.text ? offer.verein.farben.text.hex : offer.verein.farben.secondary : ''};`" @click="toggle_kind_anmeldung(kind)">
              <v-slide-x-transition><font-awesome-icon v-if="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname)" icon="fa-light fa-check" size="xl" class="mr-2" /></v-slide-x-transition>{{kind.vorname}}
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn rounded block :disabled="anmeldung.kinder.length == 0" large color="primary" @click="eltern_anmelden()">
              Anmeldung bestätigen
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndDown"
        v-model="eltern_anmeldung"
        absolute
        bottom
        temporary
        class="rounded-t-xl"
      >
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="eltern_anmeldung = false"><font-awesome-icon icon="fa-light fa-xmark" /></v-btn>
        </v-toolbar> 
        <v-row justify="center" class="mt-4">
          <v-col cols="auto" align-self="center">
            <h3 class="font-weight-light">
              Wen möchtest du anmelden?
            </h3>
          </v-col>
          <v-col cols="8" v-for="(kind, index) in kinder" :key="'kind_anmeldung_'+index">
            <v-btn rounded block large :color="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname) ? 'success' : offer.verein ? offer.verein.farben.primary : 'secondary'" :style="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname) ? '' : `color: ${offer.verein ? offer.verein.farben.text ? offer.verein.farben.text.hex : offer.verein.farben.secondary : ''};`" @click="toggle_kind_anmeldung(kind)">
              <v-slide-x-transition><font-awesome-icon v-if="anmeldung && anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname)" icon="fa-light fa-check" size="xl" class="mr-2" /></v-slide-x-transition>{{kind.vorname}}
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn rounded block :disabled="anmeldung.kinder.length == 0" large color="primary" @click="eltern_anmelden()">
              Anmeldung bestätigen
            </v-btn>
          </v-col>
        </v-row>
      </v-navigation-drawer>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, collection, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import Swal from 'sweetalert2'
import Ansprechpartner from './ansprechpartner.vue'

const Toast = Swal.mixin({
  iconColor: 'white',
  customClass: {
    popup: 'primary-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

const Toast2 = Swal.mixin({
  iconColor: 'white',
  customClass: {
    popup: 'primary-toast'
  },
  showConfirmButton: false,
  timer: 6000,
  timerProgressBar: true
})

export default {
  data() {
    return {
      tab: 0,
      eltern_anmeldung: false,
      show_gallery: false,
      anmeldung: {
        kinder: []
      }
    }
  }, 
  components: {
    ansprechpartner: Ansprechpartner
  },
  props: {
    offer: {
      type: Object,
      default: { active: false },
    },
  },
  computed: {
    altersgruppen(){
        return this.offer.altersgruppen.sort((a,b) => parseInt(a) - parseInt(b))
    },
    kinder(){
      if(this.user?.data?.kinder){
        return this.user.data.kinder.filter(item => this.offer.altersgruppen.includes(item.alter.toString()))
      }
      return []
    },
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
  },
  mounted(){
    const anmeldung = this.user.anmeldungen.find(item => item.veranstaltung == this.offer.id)
    if(anmeldung){
      this.anmeldung = Object.assign({kinder: []}, anmeldung)
    }
  },
  methods: {
    toggle_kind_anmeldung(kind){
      if(this.anmeldung.kinder.find(item => item.vorname == kind.vorname && item.nachname == kind.nachname)){
        this.anmeldung.kinder = this.anmeldung.kinder.filter(item => item.vorname != kind.vorname && item.nachname != kind.nachname)
      }
      else {
        this.anmeldung.kinder.push(kind)
      }
    },
    rand(max){
      return Math.floor(Math.random() * max);
    },
    closing() {
      this.$emit('close')
    },
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(obj) {
      if (obj.uhrzeit) {
        var uhrzeit = obj.uhrzeit.split(':')
        return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
      }
      return ''
    },
    parseDate(obj) {
      if (obj.datum) {
        var datum = obj.datum.split('-')
        return datum[2] + '.' + datum[1] + '.' + datum[0]
      }
      return ''
    },
    async anmelden(){
      await addDoc(collection(this.db, 'Anmeldungen'), {
        teilnehmer: this.user.uid,
        veranstaltung: this.offer.id
      }).then(doc => {

      })
      Toast.fire({
          icon: 'success',
          title: 'Anmeldung erfolgreich!'
        })
    },
    async cancel() {
      const anmeldung = this.user.anmeldungen.find(item => item.veranstaltung == this.offer.id)
      deleteDoc(doc(this.db, 'Anmeldungen/'+anmeldung.id)).then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Anmeldung wurde storniert!'
        })
      })
    },
    async eltern_anmelden(){
      if(this.offer.anmeldungen.length + this.anmeldung.kinder.length <= this.offer.teilnehmerzahl){
        await addDoc(collection(this.db, 'Anmeldungen'), {
          teilnehmer: this.user.uid,
          veranstaltung: this.offer.id,
          kinder: this.anmeldung.kinder
        }).then(doc => {
          this.eltern_anmeldung = false
        })
        Toast.fire({
            icon: 'success',
            title: 'Anmeldung erfolgreich!'
          })
      }
      else {
        Toast.fire({
            icon: 'error',
            title: 'Anmeldung war nicht erfolgreich, da die maximale Teilnehmerzahl dieses Angebots erreicht ist!'
          })
      }
    },
    async cancel() {
      const anmeldung = this.user.anmeldungen.find(item => item.veranstaltung == this.offer.id)
      deleteDoc(doc(this.db, 'Anmeldungen/'+anmeldung.id)).then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Anmeldung wurde storniert!'
        })
      })
    },
    format_termine(zeitraum){
        var string = ''
        zeitraum.forEach((tag, index) => {
            string += tag.wochentag + ' ('+tag.uhrzeit.beginn+' Uhr)'
            if(index+2 == zeitraum.length){
                string += ' und '
            }
            else if(index+1 < zeitraum.length){
                string += ', '
            }
        }) 
        return string
    },
    find_next_termin(termine) {
        if(termine && Array.isArray(termine)){
            var datum = new Date()
            var today = datum.getFullYear()+'-'+(datum.getMonth()+1).toString().padStart(2, '0')+'-'+datum.getDate().toString().padStart(2, '0')
            var daten = termine.filter(item => item.datum >= today)
            if(daten.length > 0){
                daten = daten.sort((a,b) => a.datum > b.datum ? 1 : a.datum < b.datum ? -1 : 0)
                return daten[0]
            }
        }
        return {beginn: '', datum: ''}
    }
  },
}
</script>

<style>
  .v-dialog--fullscreen {
    background-color: #fff;
  }
</style>
