<template>
    <v-card
        class="rounded-xl my-3"
        light
        color="white"
        @click="show_details()"
        style="height: 100%;"
    >
        <v-img
            height="150"
            width="120%"
            :aspect-ratio="900/150"
            :src="item.fotos.length > 0 ? item.fotos[0].img : item.sportart.fotos && item.sportart.fotos.length > 0 ? fallback_image ? fallback_image.img : '' : ''"
        >
            <v-overlay
                absolute
                :color="item.verein ? item.verein.farben.primary : '#121212'"
                class="text-center"
              >
                <v-avatar tile size="80">
                  <v-img
                    contain
                    :src="item.verein ? item.verein.logo : undefined"
                  ></v-img>
                </v-avatar>
            </v-overlay>
        </v-img>
        <v-card-title>{{ item.name }}</v-card-title>
        <v-card-subtitle v-if="item.sportart" class="py-0">
            <font-awesome-icon v-if="item.sportart.iconset == 'Fontawesome'" :icon="item.sportart.icon" size="xl" class="mr-1" />
            <v-icon v-if="item.sportart.iconset == 'Material' || !item.sportart.iconset">{{ item.sportart ? item.sportart.icon : '' }}</v-icon>
            {{ item.sportart ? item.sportart.name : '' }}
            <br />
            <br />
            <div v-if="item.wiederholung == 'Einmaliger Termin'">
                <v-icon>mdi-calendar</v-icon>
                {{ parseDate(item.termin) }}
                <v-icon class="ml-2">mdi-clock</v-icon>
                {{ item.termin.beginn }} Uhr
            </div>
            <div v-if="item.wiederholung == 'Mehrere Termine'">
                <small class="font-weight-bold">Nächster Termin</small><br>
                <v-icon class="mr-1">mdi-calendar</v-icon>
                {{ parseDate(find_next_termin(item.termine)) }}
                <v-icon class="ml-2">mdi-clock</v-icon>
                {{ find_next_termin(item.termine).beginn }} Uhr
            </div>
            <div v-if="item.wiederholung == 'Regelmäßige Termine' && item.zeitraum && item.zeitraum_termine && item.zeitraum_termine.length > 0 ">
                <small>Jeden {{format_termine(item.zeitraum)}}<br><b>Nächster Termin:</b></small><br>
                <v-icon>mdi-calendar</v-icon>
                {{next_termin.wochentag.substring(0,2)}}, {{ next_termin.datum_format }}
                <v-icon class="ml-2">mdi-clock</v-icon>
                {{ next_termin.uhrzeit.beginn }} Uhr
            </div>
        </v-card-subtitle>
        <sportangebotdetails
          :offer="selected_offer"
          @close="selected_offer = { active: false }"
        />
    </v-card>
</template>

<script>
import sportangebotdetails from './details.vue'
export default {
    name: 'Sportangebot-Card',
    props: ['item'],
    components: {
        sportangebotdetails
    },
    data(){
        return {
            selected_offer: { active: false },
            fallback_image: ''
        }
    },
    computed: {
        next_termin(){
            if(this.item.wiederholung == 'Regelmäßige Termine' && this.item.zeitraum && this.item.zeitraum_termine){
                var termine = this.item.zeitraum_termine.sort((a,b) => a.timestamp - b.timestamp)
                return termine[0]
            }
            else {
                return ''
            }
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            if(this.item.sportart){
                this.fallback_image = this.item.sportart?.fotos ? this.item.sportart.fotos[this.rand(this.item.sportart.fotos.length-1)] : ''
                this.item.fallback_image = this.fallback_image
            }
            else {
                setTimeout(() => {
                    this.init()
                }, 50)
            }
        },
        rand(max){
            return Math.floor(Math.random() * max);
        },
        show_details(){
            this.item.active = true
            this.item.fallback_image = this.fallback_image
            this.selected_offer = this.item
        },
        parseTime(obj) {
            var uhrzeit = obj.uhrzeit.split(':')
            return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
        },
        parseDate(obj) {
            var datum = obj.datum.split('-')
            if(datum.length == 3){
                return datum[2] + '.' + datum[1] + '.' + datum[0]
            }
            else {
                return obj.datum
            }
        },
        prevent(e){
            e.preventDefault()
        },
        format_termine(zeitraum){
            var string = ''
            zeitraum.forEach((tag, index) => {
                string += tag.wochentag
                if(index+2 == zeitraum.length){
                    string += ' und '
                }
                else if(index+1 < zeitraum.length){
                    string += ', '
                }
            }) 
            return string
        },
        find_next_termin(termine) {
            if(termine && Array.isArray(termine)){
                var datum = new Date()
                var today = datum.getFullYear()+'-'+(datum.getMonth()+1).toString().padStart(2, '0')+'-'+datum.getDate().toString().padStart(2, '0')
                var daten = termine.filter(item => item.datum >= today)
                if(daten.length > 0){
                    daten = daten.sort((a,b) => a.datum > b.datum ? 1 : a.datum < b.datum ? -1 : 0)
                    return daten[0]
                }
            }
            return {beginn: '', datum: ''}
        }
    }
}
</script>